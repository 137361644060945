import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";
/* eslint-disable */

const Botb = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })
  
  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="BotbHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Battle of the Bands</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
          Graduate Real-Time Embedded System Project to create a band out of "air instruments".</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/BattleOfTheBands"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="Botb" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="BotbAccordian">
            <div className="card">
              <div className="card-header" id="BotbOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="BotbOverview" data-parent="#BotbAccordian">
                <div className="card-body">
                A fun, Graduate Real-Time Embedded Systems project to create a band out of "air instruments". 
                Three people, making three different experiments, and performing a 4-chord song in front of a class. 
                We implemented a guitar, piano, and drum. The guitar was implemented by using an 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces"> accelerometer </a> 
                as the guitar pick, as well as a 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="This keypad has 4 buttons, where every key has its own wire line."> 1x4 keypad </a> 
                for the different chords. The piano was implemented using a 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="This keypad has 4 buttons, where every key has its own wire line."> 1x4 keypad </a> 
                to play 4 different chords. Finally, the drums were implemented using 2 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces"> accelerometers</a> 
                , and different motions will make 4 different chords. 
                This was a teambased effort, each person getting a chance to work with each component. We chose 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="This keypad has 4 buttons, where every key has its own wire line."> 1x4 keypad </a> 
                for the guitar and the piano due to the 
                <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="A material whose resistance changes when a force, pressure or mechanical stress is applied"> FSR (Force Sensitive Resistor) </a>
                being sold out on Arduino's website. 
                Overall, this was a great project to have fun and learn tremendously at the same time.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BotbGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="BotbGoals" data-parent="#BotbAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Audio output from each band members will be fed to a mixer using an RCA jack.</li>
                    <li>Audio amp, speakers, mixer, and audio cables will be provided to the “band.”</li>
                    <li>Any sensor may be used for chord implementation.</li>
                    <li>Each band is encouraged to have a “performance” look.</li>
                    <li>The less the project the better it looks, but minor props may be used such as a stick for the guitar, 
                      or a drawing of a keyboard with keys on the desk to know where keys are, etc.</li>
                    <li>Cannot play the song from the audio file directly.</li>
                    <li>Song cannot contain inappropriate lyrics.</li>
                    <li>No mosh pits.</li>
                    <li>No crowd surfing.</li>
                    <li>One distinct instrument per group member.
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Example: Bass Guitar and Guitar are different instruments.</li>
                        <li>Example: Drum Sticks may be used but not with direct strikes.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BotbGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="BotbGallery" data-parent="#BotbAccordian">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Testing" className="embed-responsive-item" src='https://player.vimeo.com/video/432015851' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Presentation" className="embed-responsive-item" src='https://player.vimeo.com/video/432016129' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BotbMaterials">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{color:"black", fontWeight:"bold"}}>
                    Provided Materials
                  </button>
                </h2>
              </div>
              <div id="collapseThree" className="collapse show" aria-labelledby="BotbMaterials" data-parent="#BotbAccordian">
                <div className="card-body">
                <ul style={{paddingLeft: "25px"}}>
                    <li><a href="https://www.pjrc.com/store/teensy32.html" data-toggle="tooltip" title="" data-original-title="A complete USB-based microcontoller development system.">Teensy3.2 </a> for each group member.</li>
                    <li><a href="https://www.pjrc.com/store/teensy3_audio.html" data-toggle="tooltip" title="" data-original-title="An Audio Adaptor Board for Teensy 3.0 – 3.6">Teensy Audio Shield </a> for each group member</li>
                    <li>Additional materials such as sensors, protoshields, or other components have to be purchased by the team.</li>
                    <li>A Four-Chord song was selected from 
                    <a href="https://en.wikipedia.org/wiki/List_of_songs_containing_the_I–V–vi–IV_progression" data-toggle="tooltip" title="" data-original-title="List of songs containing the I–V–vi–IV progression"> Wikipedia.</a>
                    </li>
                    <li>Unlimited Maker space resources (3d printing, laser cutting, etc.)</li>
                    <li>Max Budget - $20 per instrument directly from Arduino:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Guitar BOM: 
                          <ul style={{paddingLeft: "25px"}}>
                            <li>1x <a href="https://www.adafruit.com/product/64" data-toggle="tooltip" title="" data-original-title="A construction base for prototyping of electronics">Half-Sized Breadboard</a></li>
                            <li>1x <a href="https://www.adafruit.com/product/1332" data-toggle="tooltip" title="" data-original-title="This keypad has 4 buttons, where every key has its own wire line."> 1x4 keypad </a></li>
                            <li>1x <a href="https://www.adafruit.com/product/1120" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces">Accelerometer</a> </li>
                          </ul>
                        </li>
                        <li>Piano BOM:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>1x <a href="https://www.adafruit.com/product/64" data-toggle="tooltip" title="" data-original-title="A construction base for prototyping of electronics">Half-Sized Breadboard</a></li>
                            <li>1x <a href="https://www.adafruit.com/product/1332" data-toggle="tooltip" title="" data-original-title="This keypad has 4 buttons, where every key has its own wire line."> 1x4 keypad </a></li>
                          </ul>
                        </li>
                        <li>Drums BOM:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>1x <a href="https://www.adafruit.com/product/64" data-toggle="tooltip" title="" data-original-title="A construction base for prototyping of electronics">Half-Sized Breadboard</a></li>
                            <li>2x <a href="https://www.adafruit.com/product/1120" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces">Accelerometers</a></li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BotbUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="BotbUsage" data-parent="#BotbAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Prototyping:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Laser Cutting</li>
                        <li>3D Printing</li>
                        <li>Soldering</li>
                      </ul>
                    </li>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>C</li>
                        <li>Arduino IDE</li>
                        <li>SolidWorks</li>
                        <li>Ultimaker Cura</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Botb;