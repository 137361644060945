import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";

const FamFeud = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="FamFeudHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Family Feud Game</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
          Fun friends and family game voluntarily created for Engineering Week for Northrop Grumman.</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/Family-Feud"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="FamFeud" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="FamFeudAccordian">
            <div className="card">
              <div className="card-header" id="FamFeudOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="FamFeudOverview" data-parent="#FamFeudAccordian">
                <div className="card-body" style={{minHeight:"0px"}}>
                A family feud game featuring a moderator view and a contestant view. 
                Families compete to name the most popular responses to survey questions in order to win cash and prizes. 
                The moderator panel can control the sounds, effects, answers, points and questions. 
                The families will be able to have a fun oriented game taking turns naming answers. 
                When the family gets the correct answer, they will earn points on the board. 
                Three strikes, you're out! After three strikes, the other family gets to steal the points. Have fun!
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="FamFeudGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="FamFeudGoals" data-parent="#FamFeudAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Fun game for engineering week at Northrop Grumman.</li>
                    <li>Contestant:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Has three chances to sweep the board.</li>
                        <li>After three strikes the other team can steal the points.</li>
                        <li>Second round is double.</li>
                        <li>Third round is triple.</li>
                      </ul>
                    </li>
                    <li>Moderator:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Ability to control the sounds, effects.</li>
                        <li>Award points to team</li>
                        <li>Modify points in case of mistake.</li>
                        <li>Display feedback to the families if the answer is correct or incorrect.</li>
                        <li>Ability to skip a question and go to the next for funnier question.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="FamFeudGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="FamFeudGallery" data-parent="#FamFeudAccordian">
              <div className="card-body">
                  <div className="mb-0">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe title="Demo" className="embed-responsive-item" src='https://player.vimeo.com/video/460352851' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="FamFeudUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="FamFeudUsage" data-parent="#FamFeudAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Visual Studio</li>
                      </ul>
                    </li>
                    <li>Languages:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>HTML</li>
                        <li>Javascript</li>
                        <li>CSS</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default FamFeud;