import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { scroller} from 'react-scroll';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import Typist from "react-typist";
import Configs from "../../editable-stuff/configurations.json";
import "../../App";

const MainHeader = () => {
  const icons = Configs.icons;

  const [devInfo] = useState(Configs.devIntro);
  const [devDesc] = useState(Configs.devDesc);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  const scrollFunc=(e)=>{
 
    scroller.scrollTo(e, {
      duration: 1400,
      delay: 0,
      offset: 25,
      smooth: 'easeInOutQuart'
    })
  }

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };

  return (
    <div>
      <div
        id="mainHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light  d-flex align-content-center align-items-center "
        style={{marginBottom:0,height:"100vh"}}
     >
        <div style={{marginTop:"25px"}} className="container container-fluid text-center">
          <h1 className="display-1">{devInfo}</h1>
          <Typist className="lead"> {devDesc}</Typist>
          <div className="p-6" style={{paddingTop:"40px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
          {/* <div style={{display: "flex", justifyContent: "center", marginTop: "170px",}} >
            <IconButton 
              onClick={()=>scroller.scrollTo("aboutme", {
                duration: 1400,
                delay: 0,
                offset: 25,
                smooth: 'easeInOutQuart'
              })}
              className="btn btn-outline-dark btn-lg page-header-image" data-parallax="true"
              style={{margin: "170px", backgroundColor:"white",}}>
              <ArrowDownwardIcon />
            </IconButton>
          </div> */}
          <IconButton onClick={()=>scrollFunc("aboutme")} style={{position:"absolute", left:"50%", marginLeft:"-30px", backgroundColor:"white", bottom: "10px",}}>
            <ExpandMoreOutlinedIcon style={{}} fontSize="large" />
          </IconButton>
        </div>

      </div>

    </div>
  );
};

export default MainHeader;