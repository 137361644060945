// const firestoreService = require("firestore-export-import");
// const serviceAccount = require("./serviceAccountKey.json");

// const databaseURL = "https://website-a7b01.firebaseio.com"

// firestoreService.initializeApp(serviceAccount, databaseURL);

// firestoreService.restore("data.json");

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyAUpFHSeWUNINB9oKW-vCY74off_4lwA4M",
    authDomain: "webs-af846.firebaseapp.com",
    databaseURL: "https://webs-af846.firebaseio.com",
    projectId: "webs-af846",
    storageBucket: "webs-af846.appspot.com",
    messagingSenderId: "392978757442",
    appId: "1:392978757442:web:3cae487f8577c5ab5a7adb",
    measurementId: "G-S6R2SNVFB8"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);

  export const auth = firebase.auth();
  export const firestore = firebase.firestore();

  export default firebase;