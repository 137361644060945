import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';
import './aboutStyleCSS.css';
import './bootstrap.min.css';

AOS.init();

const Experience = () => {
  return (
    <div>
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0" id="experience">
          <div className="container cc-experience">
            <h5 className="display-4 mb-5 text-center">Work Experience</h5>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/NG.jpg')} width="50%" height="90%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>August 2019 - Present</p>
                      <div className="h5">Northrop Grumman</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Associate Embedded Software Engineer</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Designing software for the EA-18G Growler tactical jamming system by means of an Agile Development Environment.</li>
                      <li>Wrote proposal documents for the EA-18G Growler tactical jamming system. Documents include: Software and Firmware
                    Requirement Specs, Software Architecture Document, Interface Design Description, and Software Design Document.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/CE.png')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>June 2018 - June 2019</p>
                      <div className="h5">Consolidated Edison Company of New York, Inc.</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Engineering Aide Temporary - Summer Internship/Co-op</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Created applications in C# & VB to automate tasks such as batch converting PDFs to DWGs so they can be viewed in Adept.</li>
                      <li>Worked in teams, attended weekly meetings, created training modules in Adobe Captivate to simplify the training process.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/ymc.jpg')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>November 2017 - June 2019</p>
                      <div className="h5">Metivta Mekor Haim</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">High School Teacher</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Collaborated with other teachers and administrators in the development and evaluation of school programs.</li>
                      <li>Enforced rules for behavior among students, prepared curriculums, assignments and tests to ensure students' progress.</li>
                      <li>Graded student assignments and examinations.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/pediatrics.jpg')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>Summer 2017</p>
                      <div className="h5">Dr. Mark B. Lew Pediatrics Office</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">Digital Programming</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Wrote programs in C++ to handle specific jobs such as tracking patients, storing or retrieving data, or controlling equipment.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/rfg.png')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>July 2014 - May 2017</p>
                      <div className="h5">Royal Fashion Group, LLC.</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">Sales Account Executive</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Helped single handedly take a company from $700,000 in sales to over $1.3 million using online and email marketing.</li>
                      <li>Recommended products to customers based on trends and customer needs.</li>
                      <li>Reported monthly sales to the CEO and compared year-to-year sales by creating graphs to visualize results.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}
export default Experience;
