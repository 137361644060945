import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";

const PdfDwg = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="PdfDwgHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">PDF &#8594; DWG Converter</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
            A project to convert multi-page or a directory of PDFs to DWGs.</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/PDF-DWG-Converter"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="PdfDwg" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="PdfDwgAccordian">
            <div className="card">
              <div className="card-header" id="PdfDwgOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="PdfDwgOverview" data-parent="#PdfDwgAccordian">
                <div className="card-body">
                At Con Edison, there were many times where they received PDF drawings from contractors, that need to be converted to DWGs to work on them. 
                Asking the contractor for DWG version was often a tedious process, and AutoCAD does not support batch conversion. AutoCAD only supports single-page conversions. 
                To manually convert 1000s of PDFs or a multi-page PDF, would in itself be a full time job. The project was to create a program to do this using 
                <a href="https://en.wikipedia.org/wiki/Visual_Basic_for_Applications" data-toggle="tooltip" title="" data-original-title="An implementation of Microsoft's event-driven programming language Visual Basic 6"> VBA</a> and 
                <a href="https://help.autodesk.com/view/ACD/2017/ENU/?guid=GUID-36BF58F3-537D-4B59-BEFE-2D0FEF5A4443" data-toggle="tooltip" title="" data-original-title="an overview of the main topics and workflows for using the AutoCAD ActiveX Object Library and the Microsoft Visual Basic for Applications Integrated Development Environment"> AutoCAD API</a>.
                I created a tool where the user would specify a file or a directory of files they would like to process. 
                The program will copy the file or the directory to the server, convert them, save the files as their original name 
                with a page suffix (indicating the page it was in the PDF), send an email to the user saying the files have been converted with a link to the files/directory. 
                After the PDFs have been converted, the files will be deleted after 7 days as set by the admin to avoid overloading the server. 
                The challenge was that it had to be a tool that anyone can access, without having to download on their machine due to the limitations set by Con Edison, 
                where only an admin can download any executables. The solution was to place this program and processed in the background on a server, having a File Listener. 
                The File Listener would listen for any directories added in a certain location, and it will begin the process notifying the user when it is complete.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="PdfDwgGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="PdfDwgGoals" data-parent="#PdfDwgAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Fully designed and implemented by me.</li>
                    <li>Weekly meetings with manager showing progress for the week.</li>
                    <li>Obtaining admin approval to create the executable.</li>
                    <li>Obtaining a server to run the program on.</li>
                    <li>Obtaining a no-reply email to be used to let user track progress.</li>
                    <li>Have the ability to convert PDFs-to-DWGs.</li>
                    <li>Making it as convenient and as quick as possible for the user.</li>
                    <li>Client Side:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>User will specify the file/directory they would like to process.</li>
                        <li>The program can convert a multi-page PDF file.</li>
                        <li>The program can convert a directory of single-page PDFs.</li>
                        <li>The program can convert a directory of multi-page PDFs.</li>
                        <li>The file/directory will be copied to the server for processing.</li>
                        <li>No additional downloads for the user.</li>
                        <li>The user will receive an email with a link to the processed files.</li>
                      </ul>
                    </li>
                    <li>Server Side:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>There is a File Listener, watching for a new directory.</li>
                        <li>When it sees a new directory, it will open a process of AutoCAD with an Embedded Drawing.</li>
                        <li>The PDFs will be converted and stored on the server as DWGs.</li>
                        <li>An email will be sent to the user with a link to the files.</li>
                        <li>The files will be deleted after 7 days as specified by the admin.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="PdfDwgGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="PdfDwgGallery" data-parent="#PdfDwgAccordian">
                <div className="card-body">
                  <div className="mb-0">
                    <div className="embed-responsive embed-responsive-16by9">
                      <iframe title="Testing" className="embed-responsive-item" src='https://player.vimeo.com/video/458374664' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="PdfDwgUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="PdfDwgUsage" data-parent="#PdfDwgAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Visual Studio</li>
                        <li>AutoCAD</li>
                        <li>AutoCAD API</li>
                        <li>iTextSharp</li>
                      </ul>
                    </li>
                    <li>Languages:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>VBA</li>
                        <li>C#</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfDwg;