import React from "react";
import AOS from 'aos';
import { Link } from 'react-router-dom';

AOS.init();
const ProjectsPage = () => {
    return (
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="projectsPage">
          {/* <div className="container container-fluid p-5">
            <h1 className="display-4 pb-5">Some of my projects...</h1>
          </div> */}
          <div className="container">
            <div className="tab-content gallery mt-5">
              <div className="tab-pane active">
                <div className="ml-auto mr-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/BlindSight">  
                          <figure className="cc-effect"><img src={require('./Images/blindsight.jpg')} alt=""/>
                            <figcaption>
                              <div className="h4">BlindSight</div>
                              <p>A device with sensors to help someone who is visually disabled navigate from a location without the need for a walking stick.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/botb">
                          <figure className="cc-effect"><img src={require('./Images/battleofbands.jpg')} alt=""/>
                            <figcaption>
                              <div className="h4">Battle of the Bands</div>
                              <p>Graduate Real-Time Embedded System Project to create a band out of "air instruments".</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/bartender"> 
                          <figure className="cc-effect"><img src={require('./Images/bartender.png')} alt=""/>
                            <figcaption>
                              <div className="h4">Bartender</div>
                              <p>A bartender machine capable of mixing liquor, to make a perfectly measured drink (Completed, working on Rev. 2)</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/ppm">
                          <figure className="cc-effect"><img src={require('./Images/xilinx_board.png')} alt=""/>
                            <figcaption>
                              <div className="h4">Polytechnic Playing Machine</div>
                              <p>The goal of the term project is to enhance a game project, named Polytechnic Playing Machine, PPM.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/lung-cancer-detection"> 
                          <figure className="cc-effect"><img src={require('./Images/lungcancer.jpg')} alt=""/>
                            <figcaption>
                              <div className="h4">Lung Cancer Detection</div>
                              <p>Trained a computer to visualize and detect the stage of lung cancer based on given CT Scans using Machine 
                                Learning techniques (Convolutional Neural Networks).</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/course-eval"> 
                          <figure className="cc-effect"><img src={require('./Images/course_eval.jpeg')} alt=""/>
                            <figcaption>
                              <div className="h4">Course Evaluation System</div>
                              <p>Students may anonymously rate a lecture and professors can see the rating filtered by course and class.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/pdf-dwg"> 
                          <figure className="cc-effect"><img src={require('./Images/pdfdwg.png')} alt=""/>
                            <figcaption>
                              <div className="h4">PDF &#8594; DWG Converter</div>
                              <p>A project to convert multi-page or a directory of PDFs to DWGs.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/dwg-pdf"> 
                          <figure className="cc-effect"><img src={require('./Images/dwgpdf.png')} alt=""/>
                            <figcaption>
                              <div className="h4">DWG &#8594; PDF Converter</div>
                              <p>The goal of the project is to batch convert DWGs to PDFs.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/watermark"> 
                          <figure className="cc-effect"><img src={require('./Images/watermark.jpg')} alt=""/>
                            <figcaption>
                              <div className="h4">Watermark Adder</div>
                              <p>Take a directory of CAD drawing, add a specified watermark to it, save the drawing (as original name) and close it</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        <Link role="button" to="/projects/fam-feud"> 
                          <figure className="cc-effect"><img src={require('./Images/familyfeud.jpg')} alt=""/>
                            <figcaption>
                              <div className="h4">Family Feud Game</div>
                              <p>Fun friends and family game voluntarily created for Engineering Week for Northrop Grumman.</p>
                            </figcaption>
                          </figure>
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default ProjectsPage;
