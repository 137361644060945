import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";
/* eslint-disable */

const BlindSight = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="BlindSightHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Blind Sight</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
          A device with sensors to help someone who is visually disabled navigate from a location without the need for a walking stick.</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/BlindSightPros"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="BlindSight" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="BlindSightAccordian">
            <div className="card">
              <div className="card-header" id="BlindSightOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="BlindSightOverview" data-parent="#BlindSightAccordian">
                <div className="card-body">
                  Inspired by watching a blind person walk from the train station to the bank and inside to make a deposit, 
                  we were tasked to create a device that will help a visually disabled person walk from one location to another unassisted. 
                  Given a tight budget we had to create a wearable that detects objects in front or on the sides of a person. 
                  Google maps navigation often tells you "Turn in 100 feet". We wanted to have a similar concept. 
                  There were a few challenges though. A blind-folded person, used for testing, often does not walk straight as he is not used to it. 
                  We laser printed a box with three holes in it for sensors, with a rope tied to the person for him to have his hands free. We placed an 
                  <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="Measures distance by using ultrasonic waves"> ultrasonic sensor </a>
                  in each of the holes, one pointed ahead, right, and left to cover all directions. Inside the box was a
                  <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="A compact integrated circuit designed to govern a specific operation in an embedded system"> microcontroller </a> 
                  with a <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="A type of electronic device that’s used to produce a tone, alarm or sound"> buzzer </a> 
                  inside, to tell the user which direction and how far the obstuction is. 
                  The frequency of the buzzer would be different depending on the direction the obstruction is, making a different sound. 
                  If the obstruction was close, the user would hear a quick beep, whereas if it was further the beep would be slower, 
                  kind of like a backup camera on modern-day cars. Another part of this project was to track steps and ensure the user was walking 
                  in a straight path. We placed a box near the waist where we placed an 
                  <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces"> accelerometer </a> 
                  and wires down to the bottom of the shoe with 
                  an <a href="javascript:void(0);" data-toggle="tooltip" title="" data-original-title="A material whose resistance changes when a force, pressure or mechanical stress is applied"> FSR (Force Sensitive Resistor) </a>
                  to track steps. We also have vibrators placed on each wrist, telling the user to turn right or left. 
                  When the FSR reaches a certain step count (calibrated according to user's step size), it would set off the vibrator in the direction 
                  the user needs to turn. The user, at any time, can press a button on this box, to ensure he is walking straight. 
                  If he's not, it would vibrate telling him the direction he needs to turn till he gets to the right direction.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BlindSightGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="BlindSightGoals" data-parent="#BlindSightAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Team of 3 people.</li>
                    <li>Needs to be universal.</li>
                    <li>Navigate the user from Makerspace are to the cafeteria without hitting obstructions.</li>
                    <li>Buy pizza slice in the cafeteria.</li>
                    <li>Give user feedback to know where the obstuctions are and which direction they should go.</li>
                    <li>Allow user to have both hands free for holding things.</li>
                    <li>Ability to replace and enhance the "walking stick".</li>
                    <li>Develop electronic/embedded system that would help complete the task.</li>
                    <li>The user must be blindfolded the entire time, no visual feedback allowed.</li>
                    <li>Teammates are not allowed to help the person navigate.</li>
                    <li>No direct tactile feedback (you can use a stick, but cannot feel with a stick, placing a sensor on it is fine).</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BlindSightGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="BlindSightGallery" data-parent="#BlindSightAccordian">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Soldering</h5>
                      <img src={require('./Media/BlindSight/Soldering.jpg')} alt=""/>
                    </div>
                    <div className="col-md-4">
                      <h5>Construction</h5>
                      <img src={require('./Media/BlindSight/Construction.jpg')} alt=""/>
                    </div>
                    <div className="col-md-4">
                      <h5>Microcontroller Setup</h5>
                      <img src={require('./Media/BlindSight/Microcontroller setup.jpg')} alt=""/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="embed-responsive embed-responsive-4by3">
                        <iframe title="Initial Construction" className="embed-responsive-item" src='https://player.vimeo.com/video/429062101' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="embed-responsive embed-responsive-4by3">
                        <iframe title="Test" className="embed-responsive-item" src='https://player.vimeo.com/video/429062178' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="embed-responsive embed-responsive-4by3">
                        <iframe title="Presentation" className="embed-responsive-item" src='https://player.vimeo.com/video/429062132' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BlindSightMaterials">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{color:"black", fontWeight:"bold"}}>
                    Provided Materials
                  </button>
                </h2>
              </div>
              <div id="collapseThree" className="collapse show" aria-labelledby="BlindSightMaterials" data-parent="#BlindSightAccordian">
                <div className="card-body">
                <ul style={{paddingLeft: "25px"}}>
                    <li>3x <a href="https://www.adafruit.com/product/3942" data-toggle="tooltip" title="" data-original-title="Measures distance by using ultrasonic waves"> HC-SR04 Ultrasonic Sensors</a></li>
                    <li>1x <a href="https://www.adafruit.com/product/164" data-toggle="tooltip" title="" data-original-title="Bounces IR off objects to determine how far away they are">GP2Y0A21TK0F IR Sensor</a></li>
                    <li>1x <a href="https://www.adafruit.com/product/2077" data-toggle="tooltip" title="" data-original-title="Allows for easy connections between a breadboard and an Arduino">Stackable Proto Shield</a></li>
                    <li>5x <a href="https://www.walmart.com/ip/20PCS-300V-10A-3-50mm-Pitch-2P-Male-PCB-Screw-Terminal-Block-Connector-Green/871518443?wmlspartner=wlpa&selectedSellerId=571&adid=22222222227155079247&wl0=&wl1=g&wl2=c&wl3=266340381064&wl4=aud-430887228898:pla-439086344342&wl5=9004364&wl6=&wl7=1023239&wl8=&wl9=pla&wl10=111838760&wl11=online&wl12=871518443&veh=sem"
                     data-toggle="tooltip" title="" data-original-title="A type of electrical connector where a wire is held by the tightening of a screw.">Screw Terminal Blocks</a></li>
                    <li>Unlimited Maker space resources (3d printing, laser cutting, etc.)</li>
                    <li>$50 Budget per team used for:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>2x <a href="https://www.adafruit.com/product/2488" data-toggle="tooltip" title="" data-original-title="A compact integrated circuit designed to govern a specific operation in an embedded system">Adafruit METRO 328</a></li>
                        <li>2x <a href="https://www.adafruit.com/product/1201" data-toggle="tooltip" title="" data-original-title="BZZZZZZZZZZ* Feel that? That's your little buzzing motor">Vibrating Mini Motor Disk</a></li>
                        <li>1x <a href="https://www.adafruit.com/product/160" data-toggle="tooltip" title="" data-original-title="A type of electronic device that’s used to produce a tone, alarm or sound">Piezo Buzzer</a></li>
                        <li>1x <a href="https://www.adafruit.com/product/1120" data-toggle="tooltip" title="" data-original-title="An electromechanical device used to measure acceleration forces">Accelerometer</a></li>
                        <li>1x <a href="https://www.adafruit.com/product/166" data-toggle="tooltip" title="" data-original-title="A material whose resistance changes when a force, pressure or mechanical stress is applied">FSR</a> (Force Sensitive Resistor)</li>
                        <li>1x <a href="https://www.adafruit.com/product/759" data-toggle="tooltip" title="" data-original-title="Handy for making wire harnesses or jumpering between headers on PCB's">Jumper Cables</a></li>
                        <li>1x <a href="https://www.adafruit.com/product/64" data-toggle="tooltip" title="" data-original-title="A construction base for prototyping of electronics">Half-Sized Breadboard</a></li>
                        <li>1x Pair of gloves</li>
                        <li>1x Rope</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BlindSightUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="BlindSightUsage" data-parent="#BlindSightAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Prototyping:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Laser Cutting</li>
                        <li>3D Printing</li>
                        <li>Soldering</li>
                      </ul>
                    </li>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>C</li>
                        <li>Arduino IDE</li>
                        <li>SolidWorks</li>
                        <li>Ultimaker Cura</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default BlindSight;