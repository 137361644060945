import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";
/* eslint-disable */

const Bartender = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="BartenderHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Bartender</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
            A bartender machine capable of mixing liquor, to make a perfectly measured drink (Completed, working on Rev. 2)</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/Bartender"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="Bartender" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="BartenderAccordian">
            <div className="card">
              <div className="card-header" id="BartenderOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="BartenderOverview" data-parent="#BartenderAccordian">
                <div className="card-body">
                  How many times have you gone to an event where the bartender made bad drinks? 
                  There are a lot of drinks going to waste due to this. This machine will give you the same drink, every time. 
                  The machine holds 8 stock bottles in a rear cabinet. When you open the cabinet, lights automatically turn on this way you 
                  don't waste power when the cabinet is closed. There is a 7in touchscreen display included on the machine. 
                  When you turn it on, it will automatically load the program. There are four main buttons that pop up on the screen. 
                  "Make a Drink", "Stock Up", "Menu", and "Clean". Start by placing the drinks in the rear cabinet and placing the tubing inside the drinks. 
                  On the display, you press "Stock Up" and enter which bottles you placed in the back using a layout of the machine to distinguish the location. 
                  After loading the drinks, you can select "Make A Drink". You will now have a list of drinks that can be made with the bottles you have just stocked. 
                  Even you have 1000 drinks in the menu, you will only be able to see what can be made with the current stock. 
                  Click on the item you want to make and it will start dispensing as well as light LEDs for a cool effect. 
                  A tube manifold is used so the user can see the liquid flowing throught the tubes. 
                  The "Menu" button allows you to view, edit, create, and remove recipes and menu items from the Menu. 
                  Finally, the "Clean" button allows you to quickly flush the system or fully clean the system with water.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BartenderGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="BartenderGoals" data-parent="#BartenderAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Team of 2 people.</li>
                    <li>Can hold up to 8 Bottles.</li>
                    <li>Accuracy.</li>
                    <li>Working Prototype.</li>
                    <li>Food Grade Materials.</li>
                    <li>Lights are off when cabinet is closed, on when it is open.</li>
                    <li>Touchscreen display for user convenience.</li>
                    <li>The "Make a Drink" button will only display drinks that can be made with current stock.</li>
                    <li>The user will be able to see how much is left in the bottles from the display.</li>
                    <li>Users can view, edit, create and delete recipes from the display.</li>
                    <li>Users can clean the system by choosing quick clean, full clean which flushes the system.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BartenderGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery (CLICK HERE)
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse" aria-labelledby="BartenderGallery" data-parent="#BartenderAccordian">
                <div className="card-body">
                <div className="row">
                    <div className="col-md">
                      <h5>Completed Bartender</h5>
                      <img src={require('./Media/Bartender/bartender.jpg')} alt=""/>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="GUI Design" className="embed-responsive-item" src='https://player.vimeo.com/video/460357795' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Frame Build" className="embed-responsive-item" src='https://player.vimeo.com/video/460352991' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Pump Mount" className="embed-responsive-item" src='https://player.vimeo.com/video/460353003' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Pump Wiring" className="embed-responsive-item" src='https://player.vimeo.com/video/460353040' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Test Dispense" className="embed-responsive-item" src='https://player.vimeo.com/video/460353052' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                  <br></br>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Design" className="embed-responsive-item" src='https://player.vimeo.com/video/460357766' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Cupholder" className="embed-responsive-item" src='https://player.vimeo.com/video/460357890' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <h5>Dispenser View</h5>
                      <img src={require('./Media/Bartender/dispenser-view.jpg')} alt=""/>
                    </div>
                    <div className="col-md-4">
                      <h5>Logo Design</h5>
                      <img src={require('./Media/Bartender/LogoDesign.png')} alt=""/>
                    </div>
                    <div className="col-md-4">
                      <h5>Logo Drawing</h5>
                      <img src={require('./Media/Bartender/logo-handdrawing.png')} alt=""/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BartenderMaterials">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{color:"black", fontWeight:"bold"}}>
                    Provided Materials
                  </button>
                </h2>
              </div>
              <div id="collapseThree" className="collapse show" aria-labelledby="BartenderMaterials" data-parent="#BartenderAccordian">
                <div className="card-body">
                <ul style={{paddingLeft: "25px"}}>
                    <li>2x <a href="https://www.amazon.com/Gufastore-Surface-Magnetic-Security-Adhesive/dp/B07F314V3Z/ref=sr_1_5?keywords=gufastore&qid=1571533230&sr=8-5" data-toggle="tooltip" title="" data-original-title="Sensing opening and closing of doors and windows">Magnetic Contact Switch</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B00GFIHE0U/ref=ppx_yo_dt_b_search_asin_title?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="An electronic power supply that incorporates a switching regulator to convert electrical power efficiently">Switching Power Supply</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B078XQ5MWR/ref=ppx_yo_dt_b_asin_title_o09_s00?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="Switching buck converters reduce an input voltage to a lower, regulated voltage much more efficiently than linear regulators">Step-Down Voltage Regulator</a></li>
                    <li>1x <a href="https://www.adafruit.com/product/64" data-toggle="tooltip" title="" data-original-title="A construction base for prototyping of electronics">Half-Size Breadboard</a></li>
                    <li>1x <a href="https://www.adafruit.com/product/3775" data-toggle="tooltip" title="" data-original-title="Single-board computer with wireless LAN and Bluetooth connectivity">Raspberry Pi 3 Model B+</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B0153R2A9I/ref=ppx_yo_dt_b_asin_title_o00_s00?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="Adds a truly interactive visual capability to the Raspberry Pi">7in Official Raspberry Pi Display</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B0718XZ8GG/ref=ppx_yo_dt_b_asin_title_o04_s00?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="Consumes significantly less power than other lighting options">LED Lights</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B00KTELP3I/ref=ppx_yo_dt_b_asin_title_o06_s03?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="Relays are suitable for driving high power electronic devices such as lights, electric fans and air condition.">8-Channel Relay</a></li>
                    <li>8x <a href="https://www.ebay.com/itm/Peristaltic-dosing-Pump-Tygon-LFL-6mm-ID-tube-and-can-deliver-500ml-min-DC12V/161666772861?ssPageName=STRK%3AMEBIDX%3AIT&_trksid=p2057872.m2749.l2649" data-toggle="tooltip" title="" data-original-title="A type of positive displacement pump used for pumping a variety of fluids">Peristaltic Pumps</a></li>
                    <li>1x <a href="https://www.adafruit.com/product/759" data-toggle="tooltip" title="" data-original-title="An electrical wire, or group of them in a cable, with a connector or pin at each end">Jumper Cables</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B01IB9FI2Q/ref=ppx_yo_dt_b_asin_title_o05_s00?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="">Silicon Tubing</a></li>
                    <li>1x <a href="https://www.amazon.com/gp/product/B01NH53RBH/ref=ppx_yo_dt_b_asin_title_o05_s00?ie=UTF8&psc=1" data-toggle="tooltip" title="" data-original-title="">Stainless Steel Funnel</a></li>
                    <li>1x2 Wood for the Frame</li>
                    <li>Foam Walls</li>
                    <li>Spray Paint</li>
                    <li>Unlimited Maker space resources (3d printing, laser cutting, etc.)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="BartenderUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="BartenderUsage" data-parent="#BartenderAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Prototyping:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Laser Cutting</li>
                        <li>3D Printing</li>
                        <li>Soldering</li>
                        <li>Wood Work</li>
                        <li>3D Modelling</li>
                      </ul>
                    </li>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Python</li>
                        <li>Thonny Python IDE</li>
                        <li>Linux</li>
                        <li>SolidWorks</li>
                        <li>Fusion360</li>
                        <li>Ultimaker Cura</li>
                        <li>Kivy Frontend</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Bartender;