import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';

AOS.init();

const Education = () => {
    return (
      <div>
        <div className="page-content" id="education"> 
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container cc-education">
              <h5 className="display-4 mb-5 text-center">Education</h5>
              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/hofstra.png')} width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2020 - 2022</p>
                        <div className="h5">M.S. - Engineering Management</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">Hofstra University - School of Engineering and Applied Science (SEAS)</div>
                      <p className="category">4.00 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Engineering Management</li>
                        <li>Honors: Summa Cum Laude, Dean's List 2020 - 2022</li>
                        <li>
                          Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Engineering Management: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li> Quantitative Methods, Intro to Probability and Statistics, Project Management, Total Quality Management, Quantitative Analysis for Managers, 
                                  Engineering Management Capstone Project
                                </li>
                              </ul>
                            </li>
                            <li>Business: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Financial Reporting, Marketing Analysis, Online Marketing Decisions</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/nyu.png')} width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2017 - 2019</p>
                        <div className="h5">B.S. - Computer Engineering</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">New York University Tandon School of Engineering (NYU)</div>
                      <p className="category">3.843 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Engineering</li>
                        <li>Honors: Academic Excellence Dept. Of Electrical and Computer Engineering, Magna Cum Laude, Founder's Day Award, Dean's List 2017 - 2019</li>
                        <li>
                          Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Computer Science & Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Digital Logic & State Machine Design, Discrete Structures, Computer Architecture, Design & Analysis of Algorithms, Databases, 
                                  Computer Networking, Embedded Systems and Design, Machine Learning, Offensive Security, Graduate Real-Time Embedded Systems
                                </li>
                              </ul>
                            </li>
                            <li>Electrical Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Signals & Systems, Fundamentals of Electronics, Professional Development & Presentation, Senior Design Project</li>
                              </ul>
                            </li>
                            <li>Other: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Data Analysis, General Chemistry for Engineers, Frontiers in Bioethics, Vocal Training</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/BC.jpg')} style={{paddingTop: "70px"}}width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2015 - 2017</p>
                        <div className="h5">B.S. - Computer Engineering Candidate</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">City University of New York - Brooklyn College (CUNY)</div>
                      <p className="category">3.29 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Engineering - BC/NYU Articulation Program</li>
                        <li>Honors: Dean's List, Hillel Leadership Award, Engineering Honors</li>
                        <li> Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Computer Science & Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>C++ Programming, Object Oriented Programming, Data Structures
                                </li>
                              </ul>
                            </li>
                            <li>Mathematics: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Calculus 2, Multivariable Calculus, Differential Equations, Linear Algebra</li>
                              </ul>
                            </li>
                            <li>Physics/Electrical Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Calculus-Based General Physics 1 & 2, Vector Mechanics, Electric Circuits, Electrical Measurements Lab</li>
                              </ul>
                            </li>
                            <li>Other: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>English Composition 1 & 2, Music History, Psychology</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/shaare.jpg')} style={{paddingTop: "70px"}} className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2011 - 2015</p>
                        <div className="h5">Shaare Torah High School</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">Shaare Torah High School</div>
                      <p className="category">3.81 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Engineering</li>
                        <li>Honors: School Service Award, Advanced Regents Diploma</li>
                        <li>Standardized Tests: SAT</li>
                        <li>Regents: 
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Algebra, Geometry, Trigonometry, Earth Science, Biology, Global History, US History, Hebrew, English</li>
                          </ul>
                        </li>
                        <li>Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Introductory Coding Class </li>
                            <li>Math/Science: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Algebra, Geometry, Trigonometry, Calculus 1 (College Course - University At Albany)</li>
                                <li>Earth Science, Biology, Chemistry, Physics</li>
                              </ul>
                            </li>
                            <li>History/Languages: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Global History, U.S. History</li>
                                <li>English, Hebrew</li>
                              </ul>
                            </li>
                            <li>Other: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Micro-Economics (College Course - Touro College)</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
export default Education;