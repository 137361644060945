import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import $ from 'jquery';
import Configs from "../../editable-stuff/configurations.json";

const LungCancer = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  $(function () {
    $('[data-toggle="tooltip"]').tooltip()
  })

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="LungCancerHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Lung Cancer Detection</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
            A machine learning project to predict the type of cancer a person has based on images of a person's lungs.
          </Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/dannyjanani/Lung-Cancer-Detection"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
          {/* &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/vocals">Vocals</Link> */}
        </div>
      </div>
      <div id="LungCancer" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="LungCancerAccordian">
            <div className="card">
              <div className="card-header" id="LungCancerOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="LungCancerOverview" data-parent="#LungCancerAccordian">
                <div className="card-body">
                  The goal of the project is to predict the type of cancer a person has based on images of a person's lungs. 
                  Although there are different types of cancer stages, the clincal T stage was the only one considered. 
                  Clinical T stage values range from 1 to 5 and the accuracy of the training of the network is outputted. 
                  The sample size of was quite small as there were only 422 patients given with the largest data set that could 
                  be found having around 1000, but that set had restricted access.
                  <br></br><br></br>
                  The results of the convolution were not accurate which can be attributed to a small data set and many parameters that are trying to be predicted.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="LungCancerGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Links and References
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="LungCancerGoals" data-parent="#LungCancerAccordian">
                <div className="card-body">
                  The data is from NSCLC Radiomics which are found here:
                  <ul style={{paddingLeft: "25px"}}>
                    <li><a href="https://wiki.cancerimagingarchive.net/display/Public/RIDER+Lung+PET-CT#feb29a5b6fcc43b89290329e5e09b138" data-toggle="tooltip" title="" data-original-title="Download the data here!">NSCLC Radiomics</a> is where the data was downloaded from.</li>
                    <li>The <a href="https://github.com/dannyjanani/Lung-Cancer-Detection/blob/master/Lung1.clinical.csv" data-toggle="tooltip" title="" data-original-title="Download the patient information here!">Lung Clinical CSV File</a> contains infomration on each patient like their cancer diagnosis.</li>
                    <li>The <a href="https://github.com/dannyjanani/Lung-Cancer-Detection/blob/master/doiJNLP-zohiLwie.tcia" data-toggle="tooltip" title="" data-original-title="Download the images used here!">TCIA File</a> has all of the images used.</li>
                    <li>The <a href="https://github.com/dannyjanani/Lung-Cancer-Detection/blob/master/FolderAccess.csv" data-toggle="tooltip" title="" data-original-title="Download the folder structure here!">Folder Access File</a> was created from the folder names within the extracted data in order to be able to access all the files.</li>
                  </ul>
                  The following resources were used as references:
                  <ul style={{paddingLeft: "25px"}}>
                    <li><a href="https://www.kaggle.com/c/data-science-bowl-2017" data-toggle="tooltip" title="" data-original-title="Large set of Lung Cancer Images here!">Data Science Bowl 2017: </a>
                      <br></br>
                      The data science bowl for 2017 on kaggle gave a large set of lung cancer images and asked competitors to work with it.
                    </li>
                    <li><a href="https://www.kaggle.com/gzuidhof/full-preprocessing-tutorial" data-toggle="tooltip" title="" data-original-title="3D Imaging Example here!">3D Imaging Example: </a>
                      <br></br>
                      Gives example of 3D imaging for the data set used in the data science bowl.
                    </li>
                    <li><a href="https://www.kaggle.com/sentdex/first-pass-through-data-w-3d-convnet?fbclid=IwAR0voIiPA6chiDa_rNsZHdd4479eDouj_FpBbDSA-IBvpJxQojKlNksFuXQ" data-toggle="tooltip" title="" data-original-title="3D Convolution Example here!">3D Convolution Example: </a>
                      <br></br>
                      Gives example of how to perform 3D convolution of the data set from the data science bowl.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="LungCancerGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Code and Presentation
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="LungCancerGallery" data-parent="#LungCancerAccordian">
              <div className="card-body" style={{minHeight:"0px"}}>
                  The <a href="https://github.com/dannyjanani/Lung-Cancer-Detection/blob/master/Final.ipynb" data-toggle="tooltip" title="" data-original-title="Check out the program here!">Jupyter Notebook</a> contains the live code, equations, visualizations and narrative text of the project.
                  <br></br><br></br>
                  The final presentation presented in class is found here: <a href="https://github.com/dannyjanani/Lung-Cancer-Detection/blob/master/Final.pptx" data-toggle="tooltip" title="" data-original-title="Final Presentation here!"> Final Presentation.</a>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="LungCancerMaterials">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree" style={{color:"black", fontWeight:"bold"}}>
                    Steps
                  </button>
                </h2>
              </div>
              <div id="collapseThree" className="collapse show" aria-labelledby="LungCancerMaterials" data-parent="#LungCancerAccordian">
                <div className="card-body">
                <ul style={{paddingLeft: "25px"}}>
                    <li>Import data set</li>
                    <li>Retrieve data from the first patient as a test</li>
                    <li>Define a function to work with Hounsfield units (HU)</li>
                    <li>Define a function to resample the image</li>
                    <li>Define a function to plot the 3D images</li>
                    <li>Visualize segmented lungs</li>
                    <li>Save preprocessed data to a separate script</li>
                    <li>Convolution</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="LungCancerUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="LungCancerUsage" data-parent="#LungCancerAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Python</li>
                        <li>Google Cloud</li>
                        <li>Tensorflow</li>
                        <li>Numpy</li>
                        <li>Jupiter Notebook</li>
                        <li>Anaconda</li>
                        <li>Keras</li> 
                      </ul>
                    </li>
                    <li>Knowledge Acquired:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Formulating tasks as machine learning problems.</li>
                        <li>Loading, pre-processing, and extracting features from common data sources.</li>
                        <li>Mathematically describing simple models of the data.</li>
                        <li>Fitting the models to the data and using models for prediction and estimation.</li>
                        <li>Evaluating goodness of fit and refining models.</li>
                        <li>Evaluating the performance of methods using statistical techniques.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default LungCancer;